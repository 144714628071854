import { Select } from "nlib/ui";
import { getCurrentXeroOrganizationId, getCurrentZohoOrganizationId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import FieldContainer from "../FieldContainer";
import React, { useCallback, useMemo } from "react";
import Utils from "utils/Utils";
import useDocumentContext from "hooks/useDocumentContext";

const {
  DOCUMENT_TYPES: { INVOICE, BILL, RECEIPT, SALES_RECEIPT, CREDIT_NOTE },
  DOCUMENT_PAYMENT_TYPES: { BUY, SELL }
} = DataConstants;

const DOCUMENT_TYPES = {
  [BUY]: { [INVOICE]: BILL, [RECEIPT]: RECEIPT, [CREDIT_NOTE]: CREDIT_NOTE },
  [SELL]: { [INVOICE]: INVOICE, [SALES_RECEIPT]: SALES_RECEIPT, [CREDIT_NOTE]: CREDIT_NOTE }
};

const DOCUMENT_TYPE_VARIANTS = {
  [INVOICE]: { type: INVOICE, paymentType: SELL },
  [BILL]: { type: INVOICE, paymentType: BUY },
  [RECEIPT]: { type: RECEIPT, paymentType: BUY },
  [SALES_RECEIPT]: { type: SALES_RECEIPT, paymentType: SELL }
};

const DocumentTypeField = (props) => {
  const { uiTexts } = useSelector(getTextsData);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const zohoBusiness = !!useSelector(getCurrentZohoOrganizationId);

  const {
    documentState: { type, paymentType },
    setDocumentState
  } = useDocumentContext();

  const value = DOCUMENT_TYPES?.[paymentType]?.[type];

  const documentTypes = useMemo(() => {
    return [INVOICE, BILL, RECEIPT, !xeroBusiness && !zohoBusiness && SALES_RECEIPT].filter(Boolean);
  }, [xeroBusiness, zohoBusiness]);

  const options = useMemo(() => {
    return documentTypes.map((item) => ({ value: item, label: uiTexts[item] }));
  }, [uiTexts, documentTypes]);

  const handleChange = useCallback((documentType) => {
    setDocumentState(DOCUMENT_TYPE_VARIANTS[documentType]);
  }, [setDocumentState]);

  return (
    <FieldContainer
      portal
      id="documentType"
      selectable={false}
      confidenceFields={["type", "paymentType"]}
      invalid={!value}
      label={uiTexts.type}
      placeholder={uiTexts.selectDocumentType}
      value={value}
      displayValue={Utils.arrayFind(options, "value", value)?.label}
      options={options}
      onChange={handleChange}
      component={Select}
      {...props} />
  );
};

export default React.memo(DocumentTypeField);
