import { getCurrenciesData } from "selectors/metaData";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import FieldContainer from "../FieldContainer";
import React, { useCallback, useMemo } from "react";
import SelectCurrencyInput from "nlib/common/SelectCurrencyInput";
import useDocumentContext from "hooks/useDocumentContext";

const CurrencyField = (props) => {
  const id = "currency";

  const { uiTexts } = useSelector(getTextsData);

  const currenciesData = useSelector(getCurrenciesData);

  const {
    documentState: { [id]: value },
    setDocumentState
  } = useDocumentContext();

  const currencies = useMemo(() => {
    return currenciesData.map(({ code }) => code);
  }, [currenciesData]);

  const handleChange = useCallback((newValue) => {
    setDocumentState({ [id]: newValue });
  }, [setDocumentState]);

  return (
    <FieldContainer
      portal
      id={id}
      confidenceFields={["currency"]}
      label={uiTexts.currency}
      value={value}
      placeholder={uiTexts.selectCurrency}
      invalid={(!value || !currencies.includes(value))}
      valid={currencies.includes(value)}
      displayValue={value}
      component={SelectCurrencyInput}
      onChange={handleChange}
      {...props} />
  );
};

export default React.memo(CurrencyField);
