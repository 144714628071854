import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import FieldContainer from "../FieldContainer";
import React, { useCallback } from "react";
import Utils from "utils/Utils";
import useDocumentContext from "hooks/useDocumentContext";

const DetailedTaxRateField = ({ id, name }) => {
  const { uiTexts } = useSelector(getTextsData);

  const {
    documentState: { detailedTaxRates },
    setDocumentState
  } = useDocumentContext();

  const taxRateData = Utils.arrayFindById(detailedTaxRates, id, {});

  const handleChange = useCallback((newValue) => {
    setDocumentState({
      detailedTaxRates: Utils.arrayUpdateItemById(detailedTaxRates, id, (prev) => {
        return { ...prev, value: newValue };
      })
    });
  }, [detailedTaxRates, id, setDocumentState]);

  return (
    <FieldContainer
      id={`detailedTaxRate.${id}`}
      type="number"
      step="any"
      label={name || uiTexts.vat}
      displayValue={taxRateData.value || "0"}
      value={taxRateData.value}
      onChange={handleChange} />
  );
};

export default React.memo(DetailedTaxRateField);
