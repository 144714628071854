import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { getUploadingDocumentsCount } from "selectors/documents";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import React, { useCallback } from "react";

const HeaderButtons = ({ disabled, hideUploadButton, onUploadDocumentsOpen }) => {
  const { uiTexts } = useSelector(getTextsData);

  const documentsCurrentUploadCount = useSelector(getUploadingDocumentsCount);

  const handleUploadDocumentsButtonClick = useCallback(() => {
    onUploadDocumentsOpen();
  }, [onUploadDocumentsOpen]);

  return (
    <div className={Css.headerButtons}>
      {!hideUploadButton && (
        <Button
          large primary
          data-loading={documentsCurrentUploadCount || undefined}
          disabled={disabled || !!documentsCurrentUploadCount}
          icon={documentsCurrentUploadCount ? Icons.Spinner : Icons.CloudArrowUp}
          onClick={handleUploadDocumentsButtonClick}>
          {uiTexts.uploadDocuments}
        </Button>
      )}
    </div>
  );
};

export default React.memo(HeaderButtons);
