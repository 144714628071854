import Css from "./style.module.scss";

import { getActiveOrganization } from "selectors/organizations";
import { getContactsData } from "selectors/contacts";
import { getCurrenciesData } from "selectors/metaData";
import {
  getCurrentQuickBooksRealmId,
  getCurrentXeroOrganizationId,
  getSelectedBusinessBookCloseDate,
  getSelectedBusinessData,
  getSelectedBusinessIntegrationServiceData
} from "selectors/businesses";
import { getDocumentsData } from "selectors/documents";
import { getInitialState, updateState } from "./utils";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Constants from "const/Constants";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import DocumentContext from "contexts/DocumentContext";
import DocumentsActions from "actions/DocumentsActions";
import EditForm from "./lib/EditForm";
import Form from "nlib/common/Form";
import Header from "./lib/Header";
import OldEditForm from "./lib/OldEditForm";
import PdfViewer from "nlib/common/PdfViewer";
import Preloader from "nlib/common/Preloader";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import moment from "moment";
import useDocumentUtils from "hooks/useDocumentUtils";
import useEnvVars from "hooks/useEnvVars";

const {
  DOCUMENT_TYPES: { INVOICE, BILL, RECEIPT, SALES_RECEIPT },
  DOCUMENT_PAYMENT_TYPES: { BUY, SELL },
  ADVANCED_TRANSACTION_TYPES: { EXPENSE },
  STATUSES: { TO_REPORT, EXCLUDED, EXPORTED }
} = DataConstants;

const DOCUMENT_FROZEN_STATUSES = [TO_REPORT, EXCLUDED, EXPORTED];

const EDITABLE_DOCUMENT_PROPS = [
  "amountBase",
  "amountVat",
  "amount",
  "exportAs",
  "detailedTaxRates",
  "address",
  "currency",
  "dueDate",
  "issueDate",
  "lineItems",
  "linkedDocumentsIds",
  "location",
  "originalDocumentId",
  "paymentType",
  "paymentDate",
  "payTo",
  "paid",
  "status",
  "type",
  "vendorId"
];

const DOCUMENT_TYPE_VARIANTS = {
  [INVOICE]: { type: INVOICE, paymentType: SELL },
  [BILL]: { type: INVOICE, paymentType: BUY },
  [RECEIPT]: { type: RECEIPT, paymentType: BUY },
  [SALES_RECEIPT]: { type: SALES_RECEIPT, paymentType: SELL }
};

const DEFAULTS = {
  dueDate: "",
  issueDate: "",
  originalDocumentId: ""
};

const EditDocument = ({ documentId, refetchDocuments, openDocument, onClose }) => {
  const dispatch = useDispatch();

  const [, setEnvVars] = useEnvVars();

  const { uiTexts, messages } = useSelector(getTextsData);

  const { countryCode } = useSelector(getActiveOrganization);

  const contactsData = useSelector(getContactsData);

  const documentsData = useSelector(getDocumentsData);

  const currenciesData = useSelector(getCurrenciesData);

  const {
    extraData: { integrationService } = {},
    settings: { advancedDocumentsWorkflow } = {}
  } = useSelector(getSelectedBusinessData);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const selectedBusinessBookCloseDate = useSelector(getSelectedBusinessBookCloseDate);

  const selectedBusinessIntegrationServiceData = useSelector(getSelectedBusinessIntegrationServiceData) || {};

  const [{ loading, documentData }, setState] = useState({});

  const resetDuplicatedDocumentsIds = !!(documentData && (documentData.duplicatedDocumentsIds || []).length);

  const documentFrozen = DOCUMENT_FROZEN_STATUSES.includes(documentData?.status);

  const { openPairedTransactionsWindow } = useDocumentUtils();

  const initialValues = useMemo(() => {
    const result = Utils.getProps(documentData, EDITABLE_DOCUMENT_PROPS, DEFAULTS);

    return {
      ...result,
      paid: (quickBooksBusiness && (result.type === RECEIPT) && result.exportAs === EXPENSE) || !!result.paid
    };
  }, [documentData, quickBooksBusiness]);

  const fetchData = useCallback(async() => {
    setState((prev) => ({ ...prev, loading: true }));

    const result = await dispatch(DocumentsActions.fetchDocument(documentId));

    if (!result) setEnvVars({ editItem: null });

    setState((prev) => ({
      ...prev,
      documentData: getInitialState(result, { xeroBusiness, quickBooksBusiness, countryCode, currenciesData }),
      loading: false
    }));
  }, [countryCode, currenciesData, documentId, xeroBusiness, quickBooksBusiness, setEnvVars, dispatch]);

  const refetchDocumentsData = useCallback(() => {
    refetchDocuments();
  }, [refetchDocuments]);

  const handleFormChange = useCallback((name, value, values) => {
    const { status, recogniseData } = documentData;

    let data = { [name]: value };

    if (name === "documentType") {
      const { type, paymentType } = DOCUMENT_TYPE_VARIANTS[value];

      data = { type, paymentType };
    }

    const updatedData = updateState(data, values, {
      recogniseData,
      countryCode,
      quickBooksBusiness,
      xeroBusiness,
      integrationService,
      contactsData,
      status
    });

    return { ...values, ...updatedData };
  }, [contactsData, countryCode, documentData, integrationService, quickBooksBusiness, xeroBusiness]);

  const onOpenPairedTransactionsWindow = useCallback(async() => {
    const result = await openPairedTransactionsWindow(documentData);

    if (result) {
      fetchData();
      refetchDocumentsData();
    }
  }, [documentData, fetchData, openPairedTransactionsWindow, refetchDocumentsData]);

  const handleFormSubmit = useCallback(async({ values, extraData: { toReport, toExclude } = {} }) => {
    setState((prev) => ({ ...prev, loading: true }));

    if (toReport) {
      if (selectedBusinessBookCloseDate && values.issueDate
          && moment.utc(values.issueDate).isSameOrBefore(moment.utc(selectedBusinessBookCloseDate))) {
        const closedBookTimeText = moment.utc(selectedBusinessBookCloseDate).format(Constants.DATETIME_FORMATS.DATE_TEXT_EXT);

        dispatch(
          UiActions.showModal(
            Utils.replaceTextVars(messages.closeBookWarningForDocs, {
              date: closedBookTimeText,
              service: selectedBusinessIntegrationServiceData.label
            }),
            uiTexts.warning
          )
        );

        setState((prev) => ({ ...prev, loading: false }));

        return null;
      }
    }

    if (toExclude && documentsData.status === DataConstants.STATUSES.EXPORTED) {
      const modalResult = await dispatch(
        UiActions.showModal(messages.changeExportedStatusConfirm, uiTexts.confirm, true, "sm")
      );

      if (!modalResult) {
        setState((prev) => ({ ...prev, loading: false }));

        return null;
      }
    }

    const result = await dispatch(
      DocumentsActions.editDocument(
        documentId,
        {
          ...values,
          ...(resetDuplicatedDocumentsIds ? { duplicatedDocumentsIds: [] } : null),
          lineItems: values.lineItems && values.lineItems
            .filter((lineItem) => lineItem.description.trim()).map((lineItem) => ({
              ...lineItem,
              category: values.type === SALES_RECEIPT ? undefined : lineItem.category,
              item: lineItem.item?.id ? lineItem.item : undefined
            }))
        },
        toReport,
        toExclude
      )
    );

    onClose(true);

    return result;
  }, [
    documentsData.status,
    dispatch,
    documentId,
    resetDuplicatedDocumentsIds,
    onClose,
    selectedBusinessBookCloseDate,
    selectedBusinessIntegrationServiceData.label,
    messages,
    uiTexts
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    document.body.setAttribute("data-edit-document", "");

    return () => {
      document.body.removeAttribute("data-edit-document");
    };
  }, []);

  if (!advancedDocumentsWorkflow && documentData?.sourceData?.transactionAttachment) {
    setEnvVars({ editItem: null });

    return null;
  }

  if (!documentData) {
    return (
      <div className={Css.editDocument}>
        <Preloader />
      </div>
    );
  }

  return (
    <div className={Css.editDocument}>
      <Form
        validateOnChange
        initialValidation
        disabled={loading}
        className={Css.form}
        initialValues={initialValues}
        onChange={countryCode === Countries.CZ ? handleFormChange : undefined}
        onSubmit={handleFormSubmit}>
        {() => {
          return (
            <DocumentContext.Provider value={documentData}>
              <Header
                disabled={loading}
                documentData={documentData}
                resetDuplicatedDocumentsIds={resetDuplicatedDocumentsIds}
                openDocument={openDocument}
                onClose={onClose} />
              <div className={Css.content}>
                {countryCode === Countries.CZ
                  ? (
                    <>
                      <div className={Css.documentCol}>
                        {documentData?.attachment && (
                          <PdfViewer
                            className={Css.pdfViewer}
                            attachment={documentData.attachment} />
                        )}
                      </div>
                      <div className={Css.formCol}>
                        <OldEditForm
                          disabled={loading}
                          documentData={documentData}
                          refetchDocument={refetchDocumentsData} />
                      </div>
                    </>
                  )
                  : (
                    <EditForm
                      disabled={loading}
                      documentFrozen={documentFrozen}
                      documentData={documentData}
                      openPairedTransactionsWindow={onOpenPairedTransactionsWindow} />
                  )}
              </div>
            </DocumentContext.Provider>
          );
        }}
      </Form>
    </div>
  );
};

export default React.memo(EditDocument);

export { EDITABLE_DOCUMENT_PROPS };
