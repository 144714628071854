import { Select } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import FieldContainer from "../FieldContainer";
import React, { useCallback, useMemo } from "react";
import Utils from "utils/Utils";
import useDocumentContext from "hooks/useDocumentContext";

const { ADVANCED_TRANSACTION_TYPES: { BILL, EXPENSE } } = DataConstants;

const ExportAsField = (props) => {
  const id = "exportAs";

  const { uiTexts } = useSelector(getTextsData);

  const {
    documentState: { [id]: value },
    setDocumentState
  } = useDocumentContext();

  const options = useMemo(() => {
    return [
      { value: EXPENSE, label: uiTexts.expense },
      { value: BILL, label: uiTexts.bill }
    ];
  }, [uiTexts]);

  const handleChange = useCallback((newValue) => {
    setDocumentState({ [id]: newValue });
  }, [setDocumentState]);

  return (
    <FieldContainer
      portal
      id={id}
      selectable={false}
      invalid={!value}
      label={uiTexts.exportAs}
      value={value}
      options={options}
      placeholder={uiTexts.selectValue}
      displayValue={Utils.arrayFind(options, "value", value)?.label}
      onChange={handleChange}
      component={Select}
      {...props} />
  );
};

export default React.memo(ExportAsField);
