import { checkIsBusinessUser } from "selectors/user";
import { getCurrentXeroOrganizationId, getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import FieldContainer from "../FieldContainer";
import React, { useCallback, useMemo } from "react";
import SelectContactInput from "nlib/common/SelectContactInput";
import Utils from "utils/Utils";
import useDocumentContext from "hooks/useDocumentContext";

const { DOCUMENT_PAYMENT_TYPES: { BUY } } = DataConstants;

const AddressField = (props) => {
  const id = "address";

  const { uiTexts, messages } = useSelector(getTextsData);

  const {
    extraData: { integrationService } = {},
    settings: { allowClientPayeeSelection } = {}
  } = useSelector(getSelectedBusinessData);

  const businessUser = useSelector(checkIsBusinessUser);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const {
    documentState: { [id]: value, vendorId, type, paymentType, currency },
    documentFrozen,
    recogniseData,
    setDocumentState
  } = useDocumentContext();

  const hasAddressName = !!(value?.name || "").trim();

  const buyPaymentType = paymentType === BUY;

  const active = !documentFrozen && !!hasAddressName && !vendorId;

  const confidenceFields = useMemo(() => {
    const path = `addresses.${buyPaymentType ? "sender" : "recipient"}`;

    const nameChanged = value?.name !== Utils.getPropertyByPath(recogniseData, `${path}.name`);

    return paymentType && !nameChanged && [`${path}.name`];
  }, [buyPaymentType, paymentType, recogniseData, value?.name]);

  const label = useMemo(() => {
    const first = !xeroBusiness && paymentType ? (buyPaymentType ? uiTexts.vendor : uiTexts.customer) : uiTexts.businessName;

    return `${first}${active ? ` (${uiTexts.notLinked})` : ""}`;
  }, [xeroBusiness, active, buyPaymentType, paymentType, uiTexts]);

  const handleChange = useCallback((newValue) => {
    setDocumentState({ [id]: newValue });
  }, [setDocumentState]);

  return (
    <FieldContainer
      useWidget
      withButton
      portal
      id={id}
      disabled={businessUser && !allowClientPayeeSelection}
      vendorId={vendorId}
      label={label}
      placeholder={(!xeroBusiness && (!paymentType || !type)) ? messages.selectDocumentType : uiTexts.enterContactName}
      confidenceFields={confidenceFields}
      invalid={!vendorId}
      valid={!!vendorId}
      active={active}
      value={value}
      currency={currency}
      displayValue={value?.name}
      probablyVendor={buyPaymentType}
      onlyVendors={paymentType && buyPaymentType && integrationService && !xeroBusiness}
      onlyCustomers={paymentType && !buyPaymentType && integrationService && !xeroBusiness}
      onChange={(!xeroBusiness && (!paymentType || !type)) ? undefined : handleChange}
      component={SelectContactInput}
      {...props} />
  );
};

export default React.memo(AddressField);
