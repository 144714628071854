import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Checkbox } from "nlib/ui";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Attachments from "nlib/common/Attachments";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import DropArea from "nlib/common/DropArea";
import DropBoxPicker from "nlib/common/DropBoxPicker";
import GoogleDrivePicker from "nlib/common/GoogleDrivePicker";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useMemo, useState } from "react";
import Select from "nlib/ui/Select";
import SideBar, { SideBarContent, SideBarFooter, SideBarHeader } from "nlib/common/SideBar";
import Switcher from "nlib/ui/Switcher";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";

const { DOCUMENT_FILE_TYPES } = Constants;

const { PNG, JPG, TIFF } = DOCUMENT_FILE_TYPES;

const IMAGE_TYPE_EXTENSIONS = [PNG, JPG, TIFF].map(({ extensions }) => extensions).flat();

const {
  DOCUMENT_TYPES: { BILL, INVOICE, RECEIPT, SALES_RECEIPT },
  DOCUMENT_PAYMENT_TYPES: { BUY, SELL }
} = DataConstants;

const FILE_DROP_ALLOWED_EXTENSIONS = Object.values(Constants.DOCUMENT_FILE_TYPES)
  .reduce((aggregator, { extensions }) => [...aggregator, ...extensions], []);

const FILE_DROP_ALLOWED_TYPES = Object.values(Constants.DOCUMENT_FILE_TYPES)
  .reduce((aggregator, { extensions, mimeType }) => [...aggregator, ...extensions, mimeType], []);

const Sidebar = ({ editData, onClose }) => {
  const dispatch = useDispatch();

  const { uiTexts, messages } = useSelector(getTextsData);

  const {
    extraData: { integrationService } = {},
    meta: { emailAddress }
  } = useSelector(getSelectedBusinessData);

  const [activeTypeTab, setActiveTypeTab] = useState(null);

  const [paymentType, setPaymentType] = useState(null);

  const [type, setType] = useState(null);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [merge, setMerge] = useState(false);

  const quickBooksBusiness = integrationService === IntegrationServices.QUICK_BOOKS.value;

  const options = useMemo(() => {
    return [
      { value: null, label: uiTexts.auto },
      { value: INVOICE, label: uiTexts.invoice },
      { value: BILL, label: uiTexts.bill },
      { value: RECEIPT, label: uiTexts.receipt },
      ...(quickBooksBusiness
        ? [{ value: SALES_RECEIPT, label: uiTexts.salesReceipt }]
        : [])
    ];
  }, [quickBooksBusiness, uiTexts]);

  const TypeSelectComponent = Utils.checkIsTouchDevice() ? Select : Switcher;

  const attachments = useMemo(() => {
    if (!selectedFiles) return [];

    return selectedFiles.map((file, index) => ({ id: index, originalName: file.name || "", file }));
  }, [selectedFiles]);

  const handleTypeTabChange = useCallback((value) => {
    setActiveTypeTab(value);
    setType(value === BILL ? INVOICE : value);
    setPaymentType(
      (value === INVOICE || value === SALES_RECEIPT) ? SELL
        : (
          (value === BILL || value === RECEIPT) ? BUY : ""
        )
    );
  }, []);

  const handleMergeButtonClick = useCallback(() => {
    setMerge((prev) => !prev);
  }, []);

  const handleFilesDrop = useCallback((files, fileRejections) => {
    if (files.length) {
      setSelectedFiles((prev) => [...prev, ...files]);
    } else if (fileRejections.length) {
      setSelectedFiles([]);
    }
  }, []);

  const handleGoogleDrivePickerFilesPicked = useCallback((docs, oAuthToken) => {
    onClose({
      cloudService: "gdrive",
      oAuthToken,
      googleDocs: docs,
      type,
      paymentType,
      merge
    });
  }, [onClose, type, paymentType, merge]);

  const handleDropBoxPickerFilesPicked = useCallback((files) => {
    onClose({
      cloudService: "dropbox",
      filesLinks: files,
      type,
      paymentType,
      merge
    });
  }, [onClose, type, paymentType, merge]);

  const handleCloseButtonClick = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleImportButtonClick = useCallback(() => {
    onClose({
      merge,
      type,
      paymentType,
      files: selectedFiles
    });
  }, [onClose, merge, type, paymentType, selectedFiles]);

  const handleAttachmentClick = useCallback((item) => {
    const { originalName = "", file } = item;

    const typeImage = IMAGE_TYPE_EXTENSIONS.includes(`.${originalName.split(".").pop()}`);

    if (typeImage && file) {
      dispatch(UiActions.showModalImages([URL.createObjectURL(file)]));
    }
  }, [dispatch]);

  const handleAttachmentDelete = useCallback((index) => {
    setSelectedFiles((prev) => {
      return prev.filter((file, fileIndex) => fileIndex !== index);
    });
  }, []);

  const handleEmailClick = useCallback(() => {
    navigator.clipboard.writeText(emailAddress);
    toast.info(uiTexts.copiedToClipboard);
  }, [emailAddress, uiTexts.copiedToClipboard]);

  return (
    <SideBar className={Css.sidebar}>
      <SideBarHeader onCloseClick={handleCloseButtonClick}>
        {editData ? uiTexts.editFile : uiTexts.uploadFiles}
      </SideBarHeader>
      <SideBarContent>
        <TypeSelectComponent
          className={Css.switcher}
          options={options}
          value={activeTypeTab}
          placeholder={uiTexts.selectType}
          onChange={handleTypeTabChange} />
        <Attachments
          showAll numbered
          useConfirm={false}
          attachments={attachments}
          onFileClick={handleAttachmentClick}
          onDelete={handleAttachmentDelete} />
        <DropArea
          multiple showAccepted={false}
          className={Css.dropArea}
          extensions={FILE_DROP_ALLOWED_EXTENSIONS}
          accept={FILE_DROP_ALLOWED_TYPES}
          onDrop={handleFilesDrop} />
        {!Utils.checkIsTouchDevice() && (
          <div className={Css.cloudDocumentsPickers}>
            <div className={Css.title}>{messages.uploadFromCloudStorage}:</div>
            <div className={Css.pickers}>
              <div>
                <GoogleDrivePicker onFilesPicked={handleGoogleDrivePickerFilesPicked} />
              </div>
              <DropBoxPicker onFilesPicked={handleDropBoxPickerFilesPicked} />
            </div>
          </div>
        )}
        <div className={Css.autoUploadEmailGroup}>
          <div>{messages.autoUploadDocuments}</div>
          <div className={Css.email} title={emailAddress} onClick={handleEmailClick}>
            <span>{emailAddress}</span>
            <Icons.Copy />
          </div>
        </div>
      </SideBarContent>
      <SideBarFooter>
        <div className={Css.footerContent}>
          <div className={Css.mergeCheckbox}>
            <Button outline large onClick={handleMergeButtonClick}>
              <Checkbox toggle value={merge}>
                {messages.mergeDocumentsCheckbox}
              </Checkbox>
            </Button>
          </div>
          <div className={Css.buttons}>
            <Button large outline onClick={handleCloseButtonClick}>
              {uiTexts.close}
            </Button>
            <Button
              primary large
              disabled={!selectedFiles.length || (merge && selectedFiles.length === 1)}
              onClick={handleImportButtonClick}>
              {selectedFiles.length
                ? Utils.replaceTextVars(
                  merge ? uiTexts.mergeCountDocuments : uiTexts.uploadCountDocuments,
                  { count: selectedFiles.length }
                )
                : (merge ? uiTexts.mergeDocuments : uiTexts.uploadDocuments)}
            </Button>
          </div>
        </div>
      </SideBarFooter>
    </SideBar>
  );
};

export default React.memo(Sidebar);
